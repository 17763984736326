<template>
	<main>
		<div nitrohosting-id="ayPFu3nyV4oD4fYB" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default" id="feature">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="QvWwleKQt7VmLdtg" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="rYfIUrXH7kDlLFPU" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Terms of Services</div>
						</div>
						<div nitrohosting-id="jyFGlcgP9V2Rxbb3" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="hiIeUApB9bfXvGFD" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="xHQBENA0lZS5D9us" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="wa8WSVFzCkjFSC7l" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> TOS</div>
										</div>
										<div nitrohosting-id="EDnTBeM7YFc194dF" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="1h6AG5G6araFKaUC" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> By accessing this website, you agreed to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trade mark law.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="iTDFvVhUUgUILpKa" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> All services provided by NitroHosting may be used for lawful purposes only. Transmission, storage or presentation of any information, data or material in violation of any United States federal, state or city law is prohibited. This includes, but is not limited to: copyrighted material, material we judge to be threatening or obscene, or material protected by trade secret and other statute. The subscriber agrees to indemnify and hold harmless NitroHosting from any claims resulting from the use of service which damages the subscriber or any other party.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="NLoRUot9CjJvIyQ5" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Prohibited are sites that promote any illegal activity or present content that may be damaging to NitroHosting’ servers, or any other server on the Internet. Links to such materials are also prohibited.</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="U8t0p8HRWCksdIDQ" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="bDBTUWi7tfs97CP9" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="wH2Mg1pUXu67dpq7" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Disclaimer</div>
										</div>
										<div nitrohosting-id="Pbs2hLuqMDk3XihW" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="l1jjAowFM29G111D" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting cannot be held liable for system down time, crashes or data loss. We cannot be held liable for any predicated estimate of profits which a client would have gained if their site was functioning. Certain services provided by NitroHosting are resold. Thus, certain equipment, routing, software and programming used by NitroHosting are not directly owned or written by NitroHosting. Moreover, NitroHosting holds no responsibility for the use of our clients’ accounts. Failure to comply with any terms or conditions will result in the automatic deactivation of the account in question. We reserve the right to remove any account, without advance notice for any reason without restitution, as NitroHosting sees fit.</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="fjDfKb86Nef0UuQw" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="ztmW7B0F81QXekf7" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="D3UIugwcL0ueIaFF" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Termination and Suspension</div>
										</div>
										<div nitrohosting-id="vAwo1rYg9alPzcQu" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="U0aE5jVZOfrK7CYz" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting reserves the right to terminate your service(s) if your service(s) are reported as abuses. It should include the following example but not limited to them:</span> </span>
													</li>
												</div>
											</ul>
										</div>
										<div nitrohosting-id="QQFRxkHZ5q7uALtv" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-none">
												<div nitrohosting-id="Fs84Sjrn158Td8JC" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Pirated software/websites</span> </span>
													</li>
												</div>
												<div nitrohosting-id="YlGYFej5kRpJIQmi" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Warez/illegal sites</span> </span>
													</li>
												</div>
												<div nitrohosting-id="MzQHh5vW8RATyvii" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Hacker programmes or archives</span> </span>
													</li>
												</div>
												<div nitrohosting-id="2dUczEUsSarzBcvD" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Unusual high resource usage</span> </span>
													</li>
												</div>
												<div nitrohosting-id="HS3OkPbB6DNW9zzn" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Attempting to gain unauthorized access to any account/service/database/system</span> </span>
													</li>
												</div>
												<div nitrohosting-id="yIeSKal92a8cjZ9U" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Using website hosting plugin on our Game/Custom hosting packages</span> </span>
													</li>
												</div>
												<div nitrohosting-id="SHCtiTHyikg8WSLj" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Nulled software</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="z3LzDumC24ct04aD" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="GTSkMsqalZ8sPUbc" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="12zOmiVCiDjb1IjU" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Billings</div>
										</div>
										<div nitrohosting-id="P0lmK7dulxcwlXYq" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="rJ0jYYkuaDTv5LI1" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting is allowed to transfer the needed funds for your service when such information has been provided.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="QCVEUpOw2DArwOqW" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting shall deliver invoices to the client’s email address, but we are not responsible for transition errors since you should be reminded at our billing area.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="HdIKmfpbxdLhowuu" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> You can request a refund within 24 hours after purchasing our service(s), but only with a valid reason.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="8F8uvJ4SmHU7jTSP" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Dedicated servers (and some VPS servers in certain situation) are not refundable.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="GQoyjyGiUMRk4vIa" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> If the service(s) is canceled by the client, we will not provide a refund for the remaining time of the service’s billing cycle.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="aaIYsqMffyP5SboE" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting reserves the right to refuse, cancel or suspend service, at our sole discretion.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="VO8NJaJjGWBhZ04O" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Services or products related to domain name registration will not be refunded in any reason.</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="vbvJD7mwuLlcR5Vy" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="wQPxWK7uL4BBkeHE" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="5RiZq3Ym9tZdLoUS" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Downtime</div>
										</div>
										<div nitrohosting-id="EiYo8VFbp6xW5e35" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="f1yV1Z5KlOlMnVNV" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> We are trying our best to provide you a 100% up time, but we are not responsible for the downtime caused the following reasons:</span> </span>
													</li>
												</div>
											</ul>
										</div>
										<div nitrohosting-id="Vc7ZOsDrOQoQjjK0" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-none">
												<div nitrohosting-id="dnsxEzsFWGNSavrT" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Configuration mistakes</span> </span>
													</li>
												</div>
												<div nitrohosting-id="iftaOaGItvoAfgeV" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Attacks which is targeting our system or our network</span> </span>
													</li>
												</div>
												<div nitrohosting-id="PAbAfmObatFrlsGA" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Maintenance</span> </span>
													</li>
												</div>
												<div nitrohosting-id="cHELpo8ZQqHPZ377" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Intentional or unintentional service interuption in any way</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="lHezsB8JWl2SAncM" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="xupoeMjXIGFdKUhV" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="uGy5p2bRnPSZhNil" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Account Activation and Deactivation</div>
										</div>
										<div nitrohosting-id="AUBn9PaMV1c7eTkA" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="a4Z4XOM4YcOkgiLD" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> By activating your account with NitroHosting, you agree to the above policies and disclaimer. Upon requesting activation of an account, you are required to accept these policies, guidelines and disclaimer, and a copy of your acceptance is forwarded along with your activation request to be maintained with your account information.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="388OPfWmeTc31sCk" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Any account deactivation will require around a week to be activated again.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="Ogg8ZUDHAtOHJXE5" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> Accounts which have been deactivated might need a reactivation fee based on your previous activities.</span> </span>
													</li>
												</div>
												<div nitrohosting-id="Hej7gwsimlcqSihM" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> If your account has been deactivated, we will not be returning the account credits in any form, but you can still request a credit transfer with us.</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="QwnqA3Aj1LTxHq1Q" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="GqthwlyHmRhzUEth" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="8a2JtWPc7e7JXCtp" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Important Notes</div>
										</div>
										<div nitrohosting-id="gCmRgAqsuvXWEcm8" class="nitrohosting-list">
											<ul class="nitrohosting-list-ul nitrohosting-list-type-square">
												<div nitrohosting-id="L7KH9xwKne6omwFT" class="nitrohosting-list_item">
													<li class="nitrohosting-list-li">
														<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item"> NitroHosting reserves the right to amend any or all of the above policies, guidelines and disclaimer without notification. We also retain the right to increase any pricing and make changes to our account plans without notification.</span> </span>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	export default {
		name: "Terms",
		metaInfo: {
			title: "Terms of Services",
			titleTemplate: "%s - NitroHosting"
		}
	};

	let header = document.querySelector(".nitrohosting-header > .nitrohosting-row");
	header.classList.add("is-fixed");
</script>

<style scoped>
	@import "../assets/css/pages/Term.css";
</style>